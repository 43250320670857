import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

class Navbar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      active: false
    };
  }

  navToggle = () => {
    this.setState({
        active: !this.state.active
    });
  }
  
  

  render() {
    
    const menuClass = this.state.active ? "navbar-start navbar-menu is-active" : "navbar-start navbar-menu"
    return (
    
      <div id="navbar">
      <nav className="navbar is-fixed-top is-transparent" role="navigation" aria-label="main navigation">
        <div className="container">
          <div className="navbar-brand" style={{marginLeft: '10px'}}>
            <a className="navbar-item" onClick={this.navToggle} href="https://zotvandezee.be">
              <img src={require("../assets/zotvandezee.png")} a="logo" alt='logo' />
            </a>
        
            <p role="button" className="navbar-burger burger navbar-toggle" aria-label="menu" aria-expanded="false" onClick={this.navToggle}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </p>
          </div>

          <div id="navbar" className={menuClass}>
            <div className="navbar-start">
                <Link className="navbar-item" onClick={this.navToggle} to='/'>Home</Link>
                <Link className="navbar-item" onClick={this.navToggle}  to='/beschrijving'>Beschrijving</Link>
                <Link  className="navbar-item" onClick={this.navToggle} to='/boeken'>Reserveren</Link>
            </div>

            <div className="navbar-end" >
              <div className="navbar-item has-dropdown is-hoverable" >
                <p className="navbar-link">
                  Extra
                </p>
                <div className="navbar-dropdown">
                 <Link className="navbar-item" onClick={this.navToggle} to='/contact'>Contact</Link>
                 <Link className="navbar-item" onClick={this.navToggle} to='/privacy'>Privacy</Link>
                 <Link className="navbar-item" onClick={this.navToggle} to='/cookies'>Cookies</Link>
                 
                </div>
              </div>
            </div>
          </div>
        </div>

      </nav>
    </div>
    );
  }
}

export default Navbar;