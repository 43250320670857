
import React from 'react';
import '../mystyles.scss';
import '../mystyles.css';
import Recaptcha from 'react-google-invisible-recaptcha';

import Form from './form';

let sitekey, url ='';

 
if (process.env.NODE_ENV !== 'production') {
  //dev key
  console.log('DEVELOPMENT VERSION - Contacting localhost')
  sitekey='6LfPPOwUAAAAAEJSlgw7TEQTUvjRdQl2jEs5Rnrv';
  url= 'http://localhost:4567/contact';
}else{
  //production key
  sitekey= '6Lc80OsUAAAAAJDHecZpmngxXAZTQJ2nL1_3NdAr';
  url= 'https://zotvandezee.be:4567/contact';
}



class ContactForm extends React.Component {
  constructor( props ) {
    super( props );
    this.state = { value: '' };
    this.state.messageSent = false;
    this.onSubmit = this.onSubmit.bind( this );
    this.onResolved = this.onResolved.bind( this );
    this.state.formData = []; 
  }
  render() {

 const collectData = input => {
   this.setState({formData : input});
   this.onSubmit();
  
 }

    return (
      <div>
        <div className="container">
        <div className="container is-fluid">
        <Form collectData = {collectData} />
       
        
        <Recaptcha style={{marginLeft: '2rem'}}
          ref={ ref => this.recaptcha = ref }
          badge = {"inline"}
          sitekey= {sitekey}
          locale= {"nl"}
          onResolved={ this.onResolved } />
          <br/>
        </div>
        
      </div>
      </div>
      
    );
  }
  onSubmit() {
    if ( '' === this.state.formData ) {
      alert( 'Validation failed! Input cannot be empty.' );
      this.recaptcha.reset();
    } else {
      this.recaptcha.execute();
    }
  }
  onResolved() {
    const captcha = this.recaptcha.getResponse() 
    //console.log( 'Recaptcha resolved with response: ' + captcha);
    this.setState({messageSent: true}); 
    //console.log('this.state.messageSent :', this.state.messageSent);
    // process data - send to api 
    fetch( url ,{
      method: 'POST',
      headers: {
        'Accept': 'application.json, text/plain, */*',
        'Content-type': 'application/json'

      },
      body: JSON.stringify( {data: this.state.formData, captcha: captcha})
    })
    .then((res) => res.json())
    .then((data)=> {alert(data.msg)});
  }
}

export default ContactForm; 