import React from 'react';
import '../../mystyles.scss';
import '../../mystyles.css';
import {Link} from 'react-router-dom';


function Cookies() {
  return (
    <div className="some-margin">
      <br/>
<h1 className="title is-1">Cookies</h1>
      <br />
<h3 className="title is-3">Wat zijn cookies?</h3>
Cookies zijn kleine bestandjes die op je computer worden opgeslagen bij het bezoeken van webpagina’s. Ze bevatten informatie, gelinkt aan een webbrowser en de specifieke website. Cookies bestaan uit twee bestanddelen: de naam en de inhoud. Ze worden in een specifieke map op je harde schijf opgeslagen en voorzien van een unieke ID en looptijd. Als je naar een bepaalde website terugkeert, dan kan die pagina door middel van het cookie de bezoeker herkennen en de historiek verder opbouwen. Sommige cookies worden van zichzelf verwijderd wanneer je de website verlaat; andere blijven op je computer staan zolang je ze niet zelf wist. <br/>
<br/>
Cookies zijn geen actieve, uitvoerende software en dus niet schadelijk voor je computer. Ze zijn ook niet altijd van commerciële aard. Cookies worden gebruikt om het bezoekersgemak te verhogen of om het surfgedrag van bezoekers in kaart te brengen. Welke en hoeveel pagina’s worden bezocht, via welke route en hoe lang blijft de bezoeker op de website hangen? Op basis van deze resultaten kan de website aangepast worden en kan er ingespeeld worden op de interesses en noden van bezoekers van de site. <br/>
<br/>
<h3 className="title is-3">Welke cookies gebruikt zotvandezee.be?</h3>
Wij maken enkel gebruik van de cookies van Google Analytics. <br/>
Google Analytics is een gratis dienst van Google waarmee statistieken van websites worden verzameld en gedetailleerd weergegeven. De beheerder van de website krijgt zo een duidelijk beeld van onder andere de bezoekersstromen, verkeersbronnen en paginaweergaves. Delen van een website of volledige websites kunnen zo in functie van het gedrag en de interesses van de bezoekers aangepast worden. Dit is cruciaal om de website aan te passen aan de gebruikers en de reden dat wij gebruik maken van deze cookies. <br/>
<br/>
<h3 className="title is-3">Wil je deze cookies blokkeren? </h3>
Ga je toch niet akkoord met het gebruik van cookies dan ben je vrij om deze te blokkeren. <br/>
<br/>
Hieronder vind je een overzicht van de mogelijkheden die de meest courante browsers aanbieden om cookies te beheren:<br/>
<br/>
- Google Chrome <br/>
Open je browser. Klik op het Chrome-menu en kies daarna instellingen. Klik op geavanceerde instellingen weergeven en daarna in het gedeelte ‘Privacy’ op de knop Instellingen voor inhoud. In het gedeelte ‘Cookies’ kan je de cookiesinstellingen aanpassen en cookies verwijderen. <br/>
<br/>
- Mozilla Firefox <br/>
Open je browser. Selecteer Privacy. Stel Firefox in op Aangepaste instellingen gebruiken voor geschiedenis. Om cookies in te schakelen, vink je Cookies van websites accepteren aan. Om cookies uit te schakelen, haal je dit vinkje weg. Firefox biedt je ook de mogelijkheid om cookies van derden (third party cookies) uit te schakelen. Daarnaast kan je ook instellen hoe lang cookies bewaard mogen blijven. Door te klikken op Cookies tonen kan je een of meerdere cookies verwijderen. <br/>
<br/>
Voor andere browsers raden we u aan de handleiding te raadplegen. <br/>
<br/>
<h3 className="title is-3">Bezwaar ?</h3>
<br/>
Hebt u ondanks de mogelijkheid om de cookies te blokkeren toch bezwaar of een opmerking ? <br/>
Gelieve dan ons <Link to="/contact">contactformulier</Link> te gebruiken. Wij hebben respect voor uw privacy en staan steeds open voor verbetering. <br/>
<br/>
    </div>
  );
}

export default Cookies;
