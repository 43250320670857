import React from 'react';


export default (props) => (
  <div>
    <div className="component">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-12 beschrijving">
            <h1 className="title has-text-centered component-title">Woonkamer</h1>
          </div>

          <div className="column is-12" >
            <div className='container component-card overflow'>
              <div className='container content has-text-left'>
                Voorzieningen woonkamer:
              <ul className='circle'>
                  <li>Flatscreen televisie</li>
                  <li>Telenet TV (80 zenders) </li>
                  <li>Gezelschapsspelletjes</li>
                  <li>Centrale verwarming</li>
                  <li>Gashaard</li>
                  <li>Verduisterende rolgordijnen</li>
                </ul>


              </div>
            </div>
          </div>




        </div>
      </div>
    </div>

  </div >
);
