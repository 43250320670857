import React from 'react';
import CookieConsent from 'react-cookie-consent';
import {Link} from 'react-router-dom'; 

function Cookie() {
  return (
    <div>
      <CookieConsent
    location="bottom"
    buttonText="Begrepen"
    cookieName="cookieConsent"
    style={{ background: "#2B373B" }}
    buttonStyle={{background: "#4BA0CA", color: "white", fontSize: "1rem" }}
    expires={150}
>
<span style={{ fontSize: "1rem" }}>
Door het gebruik van onze website aanvaard u het gebruik van cookies. Wij respecteren uw
      privacy en maken slechts gebruik van cookies waar noodzakelijk. <br/> Voor meer informatie bekijk ons <Link to="/cookies">cookiebeleid</Link>.
      </span>
</CookieConsent>
    </div>
  );
}

export default Cookie ;