import React from 'react';


export default (props) => (
  <div>
    <div className="component">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-12 beschrijving">
            <h1 className="title has-text-centered component-title">Sanitair</h1>
          </div>

          <div className="column is-12" >
            <div className='container component-card'>
              <div className='container content has-text-left text-bold'>
                Sanitaire voorzieningen:
                <br />
                <ul className='circle'>
                  <li className='text-bold'>Badkamer 1 :
                    <ul>
                      <li className='text-normal'>Douche</li>
                      <li className='text-normal'>Dubbele wastafel met spiegelkast</li>
                      <li className='text-normal'>Toilet</li>
                      <li className='text-normal'>Handdoekdroger</li>
                    </ul>
                  </li>
                  <br />
                  <li className='text-bold'>Badkamer 2 :
                    <ul>
                      <li className='text-normal'>Douche</li>
                      <li className='text-normal'>Enkele wastafel met spiegelkast</li>
                      <li className='text-normal'>Toilet</li>
                      <li className='text-normal'>Handdoekdroger</li>
                    </ul>
                  </li>
                  <br />
                  <li className='text-bold'>Apart toilet met handenwasser aan inkomhal</li>
                </ul>


              </div>
            </div>
          </div>




        </div>
      </div>
    </div>

  </div >
);
