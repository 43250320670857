import React from 'react';
import '../../mystyles.scss';
import '../../mystyles.css';
import { Link } from 'react-router-dom';


function Home() {
  return (
    <div>
      <div className="hero cover is-fullheight">
        <div className="hero-body is-fullheight">
          <div className="container has-text-centered">
            <p className="subtitle">Welkom bij</p>
            <p className="title">Zot van de zee</p>
          </div>
        </div>
      </div>
      <div className="main-content">
          <div className="section-light" id="intro">
          <div className="container is-fluid">
            <div className="column is-12">
              <h1 className="section-title">Vakantiewoning met zeezicht gelegen te Bredene</h1>
            </div>
            <div className="columns is-multiline is-vcentered is-90vw">
              <div className="column is-6">
                <div className="text-is-larger">
                  <h2 className="subtitle">Nieuw duplex appartement, gelegen op de bovenste verdieping van Residentie Sunset.
              </h2>
                </div>
                <br />
                <p>
                  Dit appartement op de zesde verdieping is gelegen aan de duinen in Bredene. De woonkamer met zeezicht is
                  eigentijds ingericht. Het is uitermate geschikt voor vakantieverhuur tot 6 personen. Vanuit dit
                  appartement kan u luxueus genieten van de duinen, het
                  strand en de zee. De vakantiewoning beschikt over ruime terrassen en 2 slaapkamers die
                  elk aansluitend een eigen badkamer met douche en toilet hebben.
            </p>
                <br />
                <div className="is-divider"></div>
              </div>
              <div className="column  right-image ">
                <img src={require("../../assets/pictures/duplex.jpg")} alt="woonkamer met zeezicht" />
              </div>
            </div>
          </div>
        </div>

        <div className="section-dark" id="troeven">
<div className="container is-fluid">
  <div className="columns is-multiline is-90vw">
    <div className="column is-12">
      <h2 className="section-title">troeven</h2>
    </div>
    <div className="column is-4 has-text-centered">
    <figure>
      <img className="rounded-corners" alt="woonkamer met moderne meubels" src={require('../../assets/pictures/duplex.jpg')} />
    </figure>
    <hr />
    <h2>
    Duplex appartement met twee slaapkamers met elk hun respectievelijke badkamer met douche en toilet.
    </h2>
    </div>
    <div className="column is-4 has-text-centered">
    <figure>
    <img className="rounded-corners" alt="appartement met ruim terras en uitzicht op zee en duinen"
    src={require("../../assets/pictures/zeezicht.jpg")} />
    </figure>
    <hr />
    <h2>
    Prachtig zicht op zee en duinen.
    </h2>
    </div>
    <div className="column is-4 has-text-centered">
    <figure>
    <img className="rounded-corners" alt="de gashaard zorgt voor een gezellige sfeer ook in de wintermaanden "
    src={require("../../assets/pictures/haard-zw.jpg")} />
    </figure>
    <hr />
    <h2>
    De eigentijdse inrichting met design meubelen en gashaard zorgen voor een gezellige huiselijke sfeer in de koude wintermaanden.
    </h2>
    </div>
    <hr />
    <div className="column is-4 has-text-centered">
    <figure>
    <img className="rounded-corners" alt="ook actheraan is er een ruim terras met prachtig uitzicht"
    src={require("../../assets/pictures/terras-achter.jpg")} />
    </figure>
    <hr />
    <h2>
    Het appartement beschikt zowel vooraan als achteraan over een ruim terras.
    </h2>
    </div>
    <div className="column is-4 has-text-centered">
    <figure>
    <img className="rounded-corners" alt="de vakantiewoning is gelegen vlakbij de prachtige duinen van Bredene"
    src={require("../../assets/pictures/duinen.jpg")} />
    </figure>
    <hr />
    <h2>
     Door de ligging vlakbij de duinen ben je op enkele minuten aan het strand.
    </h2>
    </div>
    <div className="column is-4 has-text-centered">
    <figure>
      <img className="rounded-corners" alt="het appartement beschikt over een ruime en goed uitgeruste keuken"
      src={require("../../assets/pictures/keuken.jpg")} />
    </figure>
    <hr />
    <h2>
      Het appartement beschikt over een volledig uitgeruste keuken.
    </h2>
    </div>
 
  </div>
</div>
</div>




        <div className="section-light" id="beschrijving">
          <div className="container is-fluid">
            <div className="column is-12">
              <h2 className="section-title">Beschrijving</h2>
            </div>
          
            <div className="columns is-multiline">
         
            <div className="column is-half is-offset-one-quarter extra-padding-top">
              <Link to="/beschrijving">
              <button className="button is-info is-large is-fullwidth"
                 target="_blank">Uitgebreide beschrijving</button>
              </Link>
            </div>

          </div>


        </div>
      </div>

        <div className="section-medium" id="ligging">
          <div className="container is-fluid">
            <div className="column is-12">
              <h2 className="section-title">Ligging</h2>
            </div>
            <div className="columns is-vcentered">
              <div className="column is-8">
                <a href='https://goo.gl/maps/cLs4p5xbmHpc7XZs8'>
                  <figure className="image is-3by2 img-map"></figure>
                </a>
              </div>

              <div className="column is-4 has-vertically-aligned-content">
                <p className="text-is-larger">
                  <strong>Gelegen te Bredene-aan-zee
              </strong>
                </p>
                <br />
                <p>
                  Het appartement is gelegen vlakbij het Meeting and Event Center Staf Versluys. Aan de
              voetgangersbrug over de Koninklijke Baan die naar de duinen leidt ter hoogte van strandpost 4. <br />
              Op wandelafstand zijn er verscheidene supermarkten (Delhaize, Albert Heijn, Lidl) en andere
              winkels alsook horeca.
            </p>
                <br />
                <div className="is-divider"></div>
              </div>
            </div>


          </div>
        </div>

        <div className="section-light" id="beschikbaarheid">
          <div className="container is-fluid">
            <div className="column is-12">
              <h2 className="section-title">Beschikbaarheid en reservatie</h2>
            </div>
          
            <div className="columns is-multiline">
         
            <div className="column is-half is-offset-one-quarter extra-padding-top">
              <Link to="/boeken">
              <button className="button is-info is-large is-fullwidth"
                 target="_blank">Beschikbaarheid en reservatie</button>
              </Link>
            </div>

          </div>


        </div>
      </div>

        <div className="section-dark" id="omgeving">
          <div className="container is-fluid">
            <div className="columns is-multiline is-90vw">
              <div className="column is-12">
                <h2 className="section-title">omgeving</h2>
              </div>
              <div className="column is-3">
                <figure className="image is-4by3">
                  <img className="rounded-corners" alt="ook in de winter is de kust een uitstekende plek om te wandelen"
                    src={require("../../assets/pictures/winter.jpg")} />
                </figure>
              </div>
              <div className="column is-3">
                <figure className="image is-4by3">
                  <img className="rounded-corners" alt="de nabijgelegen surfclub twins is een absolute aanrader"
                    src={require("../../assets/pictures/twins.jpg")} />
                </figure>
              </div>
              <div className="column is-3">
                <figure className="image is-4by3">
                  <img className="rounded-corners" alt="bredene heeft prachtige duinen en strand"
                    src={require("../../assets/pictures/twins-kotjes.jpg")} />
                </figure>
              </div>
              <div className="column is-3">
                <figure className="image is-4by3">
                  <img className="rounded-corners" alt="de duinen zorgen op lente- en herfstdagen voor beschutting"
                    src={require("../../assets/pictures/duin.jpg")} />
                </figure>
              </div>
              <div className="column is-3">
                <figure className="image is-4by3">
                  <img className="rounded-corners" alt="in de nabije omgeving zijn prachtige wandelpaden zowel over het strand als in bossen en polders"
                    src={require("../../assets/pictures/wandeling1.jpg")} />
                </figure>
              </div>
              <div className="column is-3">
                <figure className="image is-4by3">
                  <img className="rounded-corners" alt="gelegen vlakbij oostende"
                    src={require("../../assets/pictures/oostende.jpg")} />
                </figure>
              </div>
              <div className="column is-3">
                <figure className="image is-4by3">
                  <img className="rounded-corners" alt="er zijn aan de belgische kust verscheidene activiteien zoals bv een bezoek aan het zwin"
                    src={require("../../assets/pictures/zwin.jpg")} />
                </figure>
              </div>
              <div className="column is-3">
                <figure className="image is-4by3">
                  <img className="rounded-corners" alt="nog een aanrader zijn de garnalenvissers te paard in oostduinkerke"
                    src={require("../../assets/pictures/paard.jpg")} />
                </figure>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>

  );
}

export default Home;
