import React from 'react';
import '../mystyles.scss';
import '../mystyles.css';
import {Link} from 'react-router-dom';
import {useForm} from 'react-hook-form';

function Form({collectData}) {

  const { register, handleSubmit, errors, reset } = useForm(); // 


  const onSubmit = (values,e )=> {
    e.preventDefault();
    collectData(values);
    reset();
  };

  
  return (
    <div className="some-margin">
      <form onSubmit={handleSubmit(onSubmit)}>
      
       <div className="field">
         <label className="label">Naam</label>
         <div className="control">
           <input className="input" type="text" placeholder="Naam" name="naam" ref={register}/>
         </div>
       </div>

       <div className="field">
         <label className="label">Email</label>
         <div className="control has-icons-left has-icons-right">
           <input className="input " type="email" placeholder="Email input" name="email" ref={register({required: 'Email is verplicht',validate: value => value.includes('@') || "Email moet '@' symbool bevatten.",
       })}/>
           {errors.email &&  errors.email.Bericht}
           <span className="icon is-small is-left">
             <i className="fas fa-envelope"></i>
           </span>
           <span className="icon is-small is-right">
             <i className="fas fa-exclamation-triangle"></i>
           </span>
         </div>
       </div>

       <div className="field">
         <label className="label">Categorie</label>
         <div className="control">
           <div className="select">
             <select
             name="categorie"
             ref={register({ required: true})}>
               <option value="Algemeen">Algemeen</option>
               <option value="Beschikbaarheid">Reservatie</option>
               <option value="Beschikbaarheid">Beschikbaarheid</option>
               <option value="Probleem">Probleem</option>
               <option value="Anders">Anders</option>
             </select>
             {errors.categorie && <span>U moet een categorie kiezen.</span>}
           </div>
         </div>
       </div>

       <div className="field">
         <label className="label">Onderwerp</label>
         <div className="control">
           <input className="input" type="text" placeholder="Onderwerp" name="onderwerp" ref={register}/>
           
         </div>
       </div>

       <div className="field">
         <label className="label">Bericht</label>
         <div className="control">
           <textarea className="textarea" placeholder="Tekst bericht"
           name="bericht"
             ref={register({ required: true})}></textarea>
             {errors.bericht && <span>U moet een bericht typen.</span>}
         </div>
       </div>

       <div className="field">
         Door het gebruik van dit formulier gaat u akkoord met ons <Link to='/privacy' target='_blank' >Privacybeleid</Link>. 
       </div>
       <div className="field is-grouped">
         <div className="control">
           <button type="submit" onClick={handleSubmit(onSubmit)}  className="button is-link">Verstuur</button>
         </div>
        </div>
       


      </form>
      
    </div>
  )
}

export default Form; 
