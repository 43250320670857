import React from 'react';
import '../../mystyles.scss';
import '../../mystyles.css';
import {Dropdown} from 'react-bulma-components';
import {Link} from 'react-router-dom';
import { css } from "@emotion/core";
import ClockLoader from "react-spinners/ClockLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #4BA0CA;
`;

let url = '';

if (process.env.NODE_ENV !== 'production') {
  //dev key
  console.log('DEVELOPMENT VERSION - Contacting localhost server')
 url= 'http://localhost:4567/'; 
 // om efkes production server gebruiken
//  url= 'https://zotvandezee.be:4567/';
}else{
  //production key
  url= 'https://zotvandezee.be:4567/';
}


const Boeken = () => {



  const [state, setState] = React.useState({
    maand: "maand"
  });
  const  [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false); 

  function fetchData(e) {
    
    setState({ maand: e}); 
    let selectedMonth = e;
    if(selectedMonth && selectedMonth !==""){
      setLoading(true); 
      let _url = url;
      _url = _url + selectedMonth;
      fetch(_url)
        .then(res => res.json())
        .then(data => {
  
          setLoading(false);        
          let availability = data.output;
         // console.log("data :", availability);
         setData(availability);
          
        });
    }
    
  
  }

  const renderTable = (input) => {
    console.log('inputke :', input);
    return (
      <tr key={input[1]}>{/* we'll use the weeknumber as key */}
      <td>{input[3]}</td>
      <td>{input[4]}</td>
      <td>{input[7] !=="NIET BESCHIKBAAR" ? input[7] + "€" : ""}</td>
      <td>{input[7] !=="NIET BESCHIKBAAR" ? "ja" : "nee"}</td>
    </tr>
    )
    
    
  }
    
    return (
      <div> 
            <div className="section-medium" id="boeken">
              <div className="container is-fluid">
                <div className="columns is-multiline is-90vw">
                  <div className="column is-12">
                    <h2 className="section-title">Beschikbaarheid</h2>
                    Er wordt momenteel nog gewerkt aan onze online reservatiemodule. Voorlopig kan u het<Link to="/contact"> contactformulier </Link>
                    gebruiken om te boeken.
                  </div>
                  <div className="column is-12">

                    <div className="tile is-ancestor">
                      <div className="tile is-4 is-vertical is-parent">
                        <div className="tile is-child booking-tile">
                          
                          <Dropdown className="dropdown-boeking"  onChange={(e) => fetchData(e)} value={state.maand} >
                      <Dropdown.Item value="">Selecteer maand</Dropdown.Item>
                        <Dropdown.Item value="januari">januari</Dropdown.Item>
                        <Dropdown.Item value="februari">februari</Dropdown.Item>
                        <Dropdown.Item value="maart">maart</Dropdown.Item>
                        <Dropdown.Item value="april">april</Dropdown.Item>
                        <Dropdown.Item value="mei">mei</Dropdown.Item>
                        <Dropdown.Item value="juni">juni</Dropdown.Item>
                        <Dropdown.Item value="juli">juli</Dropdown.Item>
                        <Dropdown.Item value="augustus">augustus</Dropdown.Item>
                        <Dropdown.Item value="september">september</Dropdown.Item>
                        <Dropdown.Item value="oktober">oktober</Dropdown.Item>
                        <Dropdown.Item value="november">november</Dropdown.Item>
                        <Dropdown.Item value="december">december</Dropdown.Item>
                    </Dropdown>
                        </div>
                        
                    <ClockLoader
                      css={override}
                      size={50}
                      color={"#4BA0CA"}
                      loading={loading}
                    />
            
                      </div>
                      <div className="tile is-parent">
                        <div className="tile is-child booking-tile">
                          <p>De huurperiode loopt steeds van zaterdag 16u (check-in) tot zaterdag 10u (check-out).</p>
                          <br/>
                       <table id="priceTable" className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth table-boek">
                      
                      <thead>
                        <tr>
                        <th className="has-text-centered"id="priceTableHeader" colSpan="4">{state.maand}</th>
                        </tr>
                        <tr>
                          <th>van</th>
                          <th>tot</th>
                          <th>prijs</th>
                          <th>vrij</th>
                        </tr>
                        
                      </thead>

                      
                        
                      <tbody>
                          {data.map(renderTable)}
                      </tbody>
                    </table>
                        </div>
                      </div>
                    </div>


                  <ul className="circle">
                    <li>10% korting bij reservatie van twee weken of meer.</li>
                    <li>Prijzen zijn exclusief schoonmaak. Eindschoonmaak bedraagt 75€.</li>
                  </ul>
                  </div>



                </div>
              </div>
            </div>
            <div style={{minHeight:'20vh'}} className="section-light"></div>
      </div>
  
    );
  }


export default Boeken;