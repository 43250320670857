import React from 'react';


export default (props) => (
  <div>
    <div className="component">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-12 beschrijving">
            <h1 className="title has-text-centered component-title">Praktisch</h1>
          </div>

          <div className="column is-12" >
            <div className='container component-card'>
              <div className='container content has-text-left text-bold'>

                <ul className='circle'>
                  <li className='text-bold'>Huisdieren niet toegestaan.</li>
                  <li className='text-normal'>Gemakkelijk bereikbaar met wagen.</li>
                  <li className='text-normal'>Kusttram op 200m.</li>
                  <li className='text-normal'>Lift</li>
                  <li className='text-normal'>Draadloos internet in het hele appartement.</li>
                  <li className='text-normal'>Voldoende parkeergelegenheid in de nabijheid van het gebouw.</li>
                  <li className='text-normal'>Afvallokaal voor restafval, PMD en papier voorzien op gelijkvloers van het gebouw.</li>
                  <li className='text-normal'>Glascontainer op parking naast gebouw.</li>
                  <li className='text-normal'>Huurperiode van zaterdag 16u (check-in) tot zaterdag 10u (check-out) </li>
                  <li className='text-normal'>Slaap- en badkamers zijn niet toegankelijk voor rolstoelgebruikers.</li>
                  <li className='text-normal'>Voorschot van 30% te betalen bij boeking. Resterend bedrag 6 weken voor vertrek.  </li>
                  <li className='text-normal'>Waarborg van 400€. Deze wordt wanneer er geen schade is binnen 14 dagen terugbetaald. </li>
                  <li className='text-normal'>10% korting bij reservatie van twee weken of meer.</li>
                  <li className='text-normal'>Prijzen zijn exclusief schoonmaak. Eindschoonmaak bedraagt 75€.</li>
                </ul>



              </div>
            </div>
          </div>




        </div>
      </div>
    </div>

  </div >
);
