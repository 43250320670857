
import React from 'react';
import '../mystyles.scss';
import '../mystyles.css';
import {Link} from 'react-router-dom'; 

const Footer = () => {
  return (
  <footer className="_footer">
    <div className="container">
    <div className="columns">
      <div className="column is-4">
        <h3>Site</h3>
        <ul>
          <li><Link to="/">Home</Link> </li>
          <li><Link to="/beschrijving">Beschrijving</Link> </li>
          <li><Link to="/boeken">Prijzen en reservatie</Link> </li>
        </ul>
      </div>
      <div className="column is-4">
      <h3>Extra</h3>
        <ul>
          <li><Link to="/contact">Contacteer ons</Link> </li>
          <li><Link to="/privacy">Website Disclaimer en Privacy Policy</Link> </li>
          <li><Link to="/cookies">Cookiebeleid</Link> </li>
        </ul>
      </div>
      <div className="column is-4">
      <div className="content has-text-centered is-vcentered">
        <br/>
         <a href="https://zotvandezee.be">
              <img src={require("../assets/zotvandezee.png")} a="logo" alt='logo' />
            </a>
      <p className="smaller">©{new Date().getFullYear()} RADX BVBA.</p>

      </div>
    </div>

   
  </div>
    </div>
    
 
</footer>

  )
}

export default Footer
