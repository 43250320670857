import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';


export default (props) => (
  <div>
    <div className="component">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-12 beschrijving">
            <h1 className="title has-text-centered component-title">extra</h1>
          </div>

          <div className="column is-12" >

            <Carousel showStatus={false} showThumbs={false} dynamicHeight="true">
              <div>
                <img className="fill" src="../../pics/extra/1.jpg" alt='de afgesloten garage op het gelijkvloers' />
                <p className="legende">Garage</p>
              </div>
              <div>
                <img className="fill" src="../../pics/extra/2.jpg" alt='uitzicht op duinen en zee' />
                <p className="legende">Terras vooraan</p>
              </div>
              <div>
                <img className="fill" src="../../pics/extra/3.jpg" alt='genieten van de zonsondergang op terras met uitzicht op zee' />
                <p className="legende">Terras vooraan</p>
              </div>
              <div>
                <img className="fill" src="../../pics/extra/4.jpg" alt='prachtige strand aan de Belgische kust' />
                <p className="legende">Strand</p>
              </div>

            </Carousel>

          </div>




        </div>
      </div>
    </div>

  </div >
);
