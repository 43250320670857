import React from 'react';


export default (props) => (
  <div>
    <div className="component">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-12 beschrijving">
            <h1 className="title has-text-centered component-title">Appartement</h1>
          </div>

          <div className="column is-12" >
            <div className='container component-card overflow'>
              <div className='container content has-text-left'>
                De ruime lift die u naar de zesde verdieping brengt, bevindt zich vlakbij de garage op het gelijkvloers.
                Op de zesde verdieping treft u de inkomhal met apart toilet en berging aan, evenals de woonkamer met open keuken.
                <br />
                <br />
                De grote schuifraam in de woonkamer geeft uit op een ruim terras met frontaal zeezicht. Het terras is in de zomermaanden omstreeks de middag zonovergoten en ideaal om buiten te eten. ‘s Avonds wordt het automatisch mooi verlicht. Het terras is voorzien van tuinmeubilair.
                <br />
                <br />
                In het voor- en najaar is het heerlijk vertoeven bij de gezellige gashaard. In de ruime open keuken houd je contact met je andere vakantiegenoten. Je kookt er met zicht op zee.
                <br />
                <br />
                Het appartement is zeer luxueus uitgevoerd met onder andere houten parketvloeren in de woonkamer, hal en slaapkamers. Er is een slimme thermostaat zodat de temperatuur in de woonkamer en badkamers, afzonderlijk kan bediend worden.
                <br />
                <br />
                Op de 5de verdieping bevinden zich de slaapkamers en badkamers. U kan deze bereiken met de trap die zich in de inkomhal bevindt.
                Beide slaapkamers geven uit op een groot en zonnig terras. Dit terras is ook voorzien van tuinmeubilair.
                <br />
                <br />
                Het appartement wordt verhuurd met bijhorende afgesloten garage op het gelijkvloers van het gebouw.
                In de garage is tevens een kast voorzien met bergruimte voor strandspullen. Er zijn een aantal basis strandspullen zoals parasol, strand speelgoed, vliegers …  aanwezig en vrij voor gebruik.
                <br />
                <br />
                In de nabije omgeving zijn tal van mogelijkheden tot activiteiten:

                <ul>
                  <li>Surfclub Twins (reeds meermaals benoemd tot beste surfclub van België).</li>
                  <li>Minigolf</li>
                  <li>Go-kart circuit</li>
                  <li>Twee 18 holes golfbaan in de nabije omgeving (Royal Ostend Golf Club en Royal Zoute Golf Club - De Haan).</li>
                  <li>Tennisclub Vicogne Bredene</li>
                  <li>Fort Napoleon</li>
                  <li>Paardrijden op het strand en in de bossen.</li>
                  <li>Fiets- en wandelroutes</li>
                  <li>In de zomermaanden geniet u wekelijks van de gezellige avondmarkten en muzikale evenementen.</li>
                </ul>
              </div>
            </div>
          </div>




        </div>
      </div>
    </div>

  </div >
);
