import React from 'react';


export default (props) => (
  <div>
    <div className="component">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-12 beschrijving">
            <h1 className="title has-text-centered component-title">Berging</h1>
          </div>

          <div className="column is-12" >
            <div className='container component-card'>
              <div className='container content has-text-left text-bold'>
                Voorzieningen berging:
              <ul className='circle'>
                  <li className='text-normal'>Vestiaire</li>
                  <li className='text-normal'>Wasmachine</li>
                  <li className='text-normal'>Snoerloze stofzuiger</li>
                  <li className='text-normal'>Robotstofzuiger</li>

                </ul>


              </div>
            </div>
          </div>




        </div>
      </div>
    </div>

  </div >
);
