import React from 'react';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import {Dropdown} from 'react-bulma-components';
import * as Subs from './sub';


class Uitrusting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeComponent: 'Woonkamer',


    }
    this.updateState = this.updateState.bind(this);
  };
  updateState(input) {
    this.setState({ activeComponent: input });
    //this.renderActiveElement(this.state.activeComponent);
  };



  render() {
    //console.log(this.state.activeComponent);
    return (
      <div>
          <div className="section-light">
          <div className='container'>
              <div className="columns is-multiline">
                <div className="column is-12">
                  <h2 className="section-title">Uitrusting</h2>
                </div>
                <div className="column is-12">
                
                  <div className="tabs is-hidden-mobile">
                    <ul>
                    <li><button onClick={(e) => this.updateState(e.target.value)} className={(this.state.activeComponent === 'Woonkamer') ? 'button is-light' : 'button is-white'} value='Woonkamer'>Woonkamer</button></li>
                  <li><button onClick={(e) => this.updateState(e.target.value)} className={(this.state.activeComponent === 'Keuken') ? 'button is-light' : 'button is-white'} value='Keuken'>Keuken</button></li>
                  <li><button onClick={(e) => this.updateState(e.target.value)} className={(this.state.activeComponent === 'Berging') ? 'button is-light' : 'button is-white'} value='Berging'>Berging</button></li>
                  <li><button onClick={(e) => this.updateState(e.target.value)} className={(this.state.activeComponent === 'Slaapkamers') ? 'button is-light' : 'button is-white'} value='Slaapkamers'>Slaapkamers</button></li>
                  <li><button onClick={(e) => this.updateState(e.target.value)} className={(this.state.activeComponent === 'Sanitair') ? 'button is-light' : 'button is-white'} value='Sanitair'>Sanitair</button></li>
                  <li><button onClick={(e) => this.updateState(e.target.value)} className={(this.state.activeComponent === 'Praktisch') ? 'button is-light' : 'button is-white'} value='Praktisch'>Praktisch</button></li>
                    </ul>
                  </div>
      <div className="columns is-mobile is-hidden-desktop is-vcentered">
        <div className="column is-half is-gapless some-margin">
          <div className="is-hidden-desktop">Selecteer hier voor informatie over het betreffende deel.</div>
        </div>
        <div className="column is-gapless">
                <Dropdown className="is-hidden-desktop" onChange={(e) => {console.log("e", e);this.updateState(e)}} value={this.state.activeComponent}>
      <Dropdown.Item value="Woonkamer" >
        Woonkamer
      </Dropdown.Item>
      <Dropdown.Item value="Keuken">
        Keuken
      </Dropdown.Item>
      <Dropdown.Item value="Berging">
        Berging
      </Dropdown.Item>
      <Dropdown.Item value="Slaapkamers">
        Slaapkamers
      </Dropdown.Item>
      <Dropdown.Item value="Sanitair">
        Sanitair
      </Dropdown.Item>
      <Dropdown.Item value="Praktisch">
        Praktisch
      </Dropdown.Item>
   </Dropdown>
        </div>
      </div>
      
      








                  <div>
                  {this.renderActiveElement(this.state.activeComponent)}
                </div>
                </div>
        </div>
      </div>
    </div>
  </div>

      
    );
  }

  renderActiveElement(activeElement) {

    const Sub = Subs[activeElement];
    return <Sub />;

  }


}

export default Uitrusting;

