import React from 'react';
import '../../mystyles.scss';
import '../../mystyles.css';
import Indeling from '../indeling.jsx';
import Uitrusting from '../Uitrusting.jsx';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

//TODO: lange teksten van foto's korter maken en als je klikt op link ga je naar onderkant pagina, ook voor reserveren. 

function Beschrijving() {
  return (
    <div>
      <div className="section-dark">
        <div className="some-margin is-fluid">
          <div className="column is-12">
            <h1 className="section-title">Foto's</h1>
          </div>
          
            <div className="some-margin fill">
              <Carousel showIndicators={false} showStatus={false} showThumbs={false} dynamicHeight="true">
                <div>
                  <img className="portrait" src={require("../../assets/pictures/beschrijving/1.jpg")} alt='ruime teak tafel met comfortabele stoelen' />
                  <p className="legende">Eetkamer</p>
                </div>
                <div>
                  <img className="fill" src={require("../../assets/pictures/beschrijving/2.jpg")} alt='de eetkamer is gelegen vlak naast de open keuken' />
                  <p className="legende">Eetkamer</p>
                </div>
                <div>
                  <img className="fill" src={require("../../assets/pictures/beschrijving/3.jpg")} alt='ruime eetkamer en keuken' />
                  <p className="legende">Eetkamer</p>
                </div>
                <div>
                  <img className="fill" src={require("../../assets/pictures/beschrijving/3a.jpg")} alt='ruime living en eetkamer' />
                  <p className="legende">Woonkamer</p>
                </div>
                <div>
                  <img className="fill" src={require("../../assets/pictures/beschrijving/4.jpg")} alt='modern ingerichtte woonkamer met gashaard en flatscreen televisie' />
                  <p className="legende">Woonkamer</p>
                </div>
                <div>
                  <img className="fill" src={require("../../assets/pictures/beschrijving/5.jpg")} alt='de open keuken staat in verbinding met de woonkamer' />
                  <p className="legende">Keuken - zicht op woonkamer</p>
                </div>
                <div>
                  <img className="fill" src={require("../../assets/pictures/beschrijving/6.jpg")} alt='ruime keuken met graniet werkblad voorzien van alle luxe zoals inductiekookplaat, vaatwasser, combi-oven, ...' />
                  <p className="legende">Keuken</p>
                </div>
                <div>
                  <img className="fill" src={require("../../assets/pictures/beschrijving/7.jpg")} alt='espresso machine' />
                  <p className="legende">Keuken</p>
                </div>
                <div>
                <img className="fill" src={require("../../assets/pictures/beschrijving/8.jpg")} alt='beide slaapkamers geven uit op het ruime terras achteraan' />
                <p className="legende">Slaapkamer 1</p>
              </div>
              <div>
                <img className="fill" src={require("../../assets/pictures/beschrijving/8a.jpg")} alt='beide slaapkamers geven uit op het ruime terras achteraan' />
                <p className="legende">Slaapkamer 1</p>
              </div>
              <div>
                <img className="fill" src={require("../../assets/pictures/beschrijving/9.jpg")} alt='de grote slaapkamer is ingericht als familiekamer voor vier personen met een opklapbaar stapelbed' />
                <p className="legende">Slaapkamer 1 met bedkast geopend</p>
              </div>
              <div>
                <img className="fill" src={require("../../assets/pictures/beschrijving/9a.jpg")} alt='de grote slaapkamer is ingericht als familiekamer voor vier personen met een opklapbaar stapelbed' />
                <p className="legende">Slaapkamer 1 met bedkast gesloten</p>
              </div>
              <div>
                <img className="fill" src={require("../../assets/pictures/beschrijving/10.jpg")} alt='de slaapkamers zijn stijlvol ingericht' />
                <p className="legende">Slaapkamer 2</p>
              </div>
              <div>
                <img className="fill" src={require("../../assets/pictures/beschrijving/11.jpg")} alt='zicht op de polders' />
                <p className="legende">Slaapkamer 2</p>
              </div>
              <div>
                <img className="fill" src={require("../../assets/pictures/beschrijving/12.jpg")} alt='op het terras achteraan kan u in de voormiddag genieten van de zon' />
                <p className="legende">Terras achteraan</p>
              </div>
              <div>
                <img className="fill" src={require("../../assets/pictures/beschrijving/13.jpg")} alt='terras achteraan' />
                <p className="legende">Terras achteraan</p>
              </div>
              <div>
                <img className="portrait" src={require("../../assets/pictures/beschrijving/14.jpg")} alt='badkamer met toilet, inloopdouche en dubbele wastafel' />
                <p className="legende">Badkamer 1</p>
              </div>
              <div>
                <img className="portrait" src={require("../../assets/pictures/beschrijving/14a.jpg")} alt='badkamer met toilet, inloopdouche en dubbele wastafel' />
                <p className="legende">Badkamer 1</p>
              </div>
              <div>
                <img className="portrait" src={require("../../assets/pictures/beschrijving/15.jpg")} alt='dubbele wastafel met spiegelkast' />
                <p className="legende">Badkamer 1</p>
              </div>
              <div>
                <img className="portrait" src={require("../../assets/pictures/beschrijving/16.jpg")} alt='tweede badkamer met douche, toilet en enkele wastafel' />
                <p className="legende">Badkamer 2</p>
              </div>
              <div>
                <img className="portrait" src={require("../../assets/pictures/beschrijving/17.jpg")} alt='tweede badkamer met douche, toilet en enkele wastafel' />
                <p className="legende">Badkamer 2</p>
              </div>
              <div>
                <img className="fill" src={require("../../assets/pictures/beschrijving/18.jpg")} alt='overdekte garage in het gebouw op het gelijkvloers' />
                <p className="legende">Garage</p>
              </div>
              <div>
                <img className="fill" src={require("../../assets/pictures/beschrijving/19.jpg")} alt='prachtige zonsondergang te bewonderen vanop het terras' />
                <p className="legende">Terras vooraan</p>
              </div>
              <div>
                <img className="fill" src={require("../../assets/pictures/beschrijving/20.jpg")} alt='ruim terras vooraan met panoramisch zeezicht' />
                <p className="legende">Terras vooraan</p>
              </div>
              <div>
                <img className="portrait" src={require("../../assets/pictures/beschrijving/21.jpg")} alt='nabij het woongedeelte op de zesde verdieping is een apart toilet naast de bergruimte' />
                <p className="legende">Toilet</p>
              </div>
              <div>
                <img className="portrait" src={require("../../assets/pictures/beschrijving/22.jpg")} alt='inkomhal met schoenkast' />
                <p className="legende">Inkomhal</p>
              </div>
              <div>
                <img className="fill" src={require("../../assets/pictures/beschrijving/23.jpg")} alt='genieten van het strand dat direct beschikbaar is via de brug' />
                <p className="legende">Strand</p>
              </div>

              </Carousel>
            </div> 
        </div>
      </div>
    <Uitrusting></Uitrusting>
    <Indeling></Indeling>
 



 </div>
  );
}

export default Beschrijving;
