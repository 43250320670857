import React from 'react';
import '../mystyles.scss';
import '../mystyles.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';



export default (props) => (
  <div>
    <div className="section-medium">
        <div className="columns is-multiline">
          <div className="column is-12 beschrijving">
            <h1 className="section-title">Indeling</h1>
          </div>
          <div className="column is-12" >
            <Carousel showArrows={true} showStatus={false} showThumbs={false} dynamicHeight="true">
              <div style={{ backgroundColor: '#b5c6d1' }} >
                <img className="fill" src={require("../assets/svg/6de.svg")} alt='plan woongedeelte gelegen op de 6de verdieping' />
                <p className="plan-legende">6de verdieping</p>
              </div>
              <div style={{backgroundColor: '#b5c6d1'}} >
                <img className="fill" src={require("../assets/svg/5de.svg")} alt='plan slaapkamers en sanitair gelegen op de 5de verdieping' />
                <p className="plan-legende">5de verdieping</p>
              </div>
            </Carousel>
          </div>
        </div>
    </div>
  </div >
);
