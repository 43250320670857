import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Routes from './router';
import { BrowserRouter as Router} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Cookie from './components/sub/cookie';



ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Navbar />
        <Routes/>
      <Footer />
      <Cookie/>
    </Router>
  

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
