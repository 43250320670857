import React from 'react';
import { Switch, Route } from 'react-router';

import Beschrijving from './components/pages/Beschrijving';
import Boeken from './components/pages/Boeken.jsx';
import Contact from './components/contactForm.jsx';
import Cookies from './components/pages/Cookies.jsx';
import Home from './components/pages/Home.jsx';
import Privacy from './components/pages/Privacy.jsx';


function Routes  () {
  return (
    <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/beschrijving" component={Beschrijving} />
    <Route path="/boeken" component={Boeken} />
    <Route path="/contact" component={Contact} />
    <Route path="/cookies" component={Cookies} />
    <Route path="/privacy" component={Privacy} />
  </Switch>
  );
}



export default Routes;

