import React from 'react';


export default (props) => (
  <div>
    <div className="component">
      <div className="container">
        <div className="columns is-multiline">
          <div className="column is-12 beschrijving">
            <h1 className="title has-text-centered component-title">Keuken</h1>
          </div>

          <div className="column is-12" >
            <div className='container component-card overflow'>
              <div className='container content has-text-left text-bold'>
                Voorzieningen keuken:
              <ul className='circle'>
                  <li className='text-bold'>Apparaten:
                    <ul>
                      <li className='text-normal'>Vaatwasser</li>
                      <li className='text-normal'>Inductiekookplaat</li>
                      <li className='text-normal'>Combi-oven</li>
                      <li className='text-normal'>Koelkast met 4 sterren vriesvak.</li>
                      <li className='text-normal'>Espressomachine, waterkoker, mixer, ...</li>
                    </ul>
                    <br />
                  </li>
                  <li className='text-bold'>Algemeen:
                    <ul>
                      <li className='text-normal'>Bestek</li>
                      <li className='text-normal'>Ruim assortiment servies</li>
                      <li className='text-normal'>Ruim assortiment glazen (wijn, bier, ...)</li>
                      <li className='text-normal'>Uitgebreid aanbod keukengerei</li>
                      <li className='text-normal'>Potten, pannen en schalen</li>
                      <li className='text-normal'>Ruim werkblad</li>
                      <li className='text-normal'>Voldoende bergruimte</li>
                      <li className='text-normal'>Er is steeds een keukenpakket voorzien met vaatdoeken, handdoeken, spons, afwasmiddel en vaatwastabletten.</li>
                    </ul>
                  </li>
                </ul>


              </div>
            </div>
          </div>




        </div>
      </div>
    </div>

  </div >
);
