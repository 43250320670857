import React from 'react';
import '../../mystyles.scss';
import '../../mystyles.css';
import {Link}from 'react-router-dom';


function Privacy() {
  return (
    <div className="some-margin">
      <h1 className="title is-1">Website Disclaimer en Privacy Policy</h1>
      Dit privacybeleid hoort bij de website van zotvandezee.be Deze website is eigendom van RADX BVBA :<br/>
      <br/>
      Contactgegevens: <br/>
      RADX BVBA <br/>
      Singel 25 <br/>
      2150 Borsbeek <br/>
      info@radx.eu <br/>
      <br/>
      Door de toegang tot en het gebruik van de website verklaart u zich uitdrukkelijk akkoord met de volgende algemene voorwaarden. <br/>
      <br/>
      <h2 className="title is-2">Intellectuele eigendomsrechten</h2>
      De inhoud van deze site, met inbegrip van de merken, logo’s, tekeningen, data, product- of bedrijfsnamen, teksten, foto's, beelden e.d. zijn beschermd door intellectuele rechten en behoren toe aan RADX BVBA en rechthoudende derden. <br/>
      <br/>
      <h2 className="title is-2">Beperking van aansprakelijkheid</h2>
      De informatie op de webstite is van algemene aard. De informatie is niet aangepast aan persoonlijke of specifieke omstandigheden, en kan dus niet als een persoonlijk, professioneel of juridisch advies aan de gebruiker worden beschouwd. <br/>
      <br/>
      Wij leveren grote inspanningen opdat de ter beschikking gestelde informatie volledig, juist, nauwkeurig en bijgewerkt zou zijn. Ondanks deze inspanningen kunnen onjuistheden zich voordoen in de ter beschikking gestelde informatie. Indien de verstrekte informatie onjuistheden zou bevatten of indien bepaalde informatie op of via de site onbeschikbaar zou zijn, zal RADX BVBA de grootst mogelijke inspanning leveren om dit zo snel mogelijk recht te zetten. <br/>
      <br/>   
      RADX BVBA kan evenwel niet aansprakelijk worden gesteld voor rechtstreekse of onrechtstreekse schade die onstaat uit het gebruik van de informatie op deze site. <br/>
      <br/>
      Indien u onjuistheden zou vaststellen in de informatie die via de site ter beschikking wordt gesteld, kan u de beheerder van de site contacteren. <br/>
      <br/>
      De inhoud van de site (links inbegrepen) kan te allen tijde zonder aankondiging of kennisgeving aangepast, gewijzigd of aangevuld worden. RADX BVBA geeft geen garanties voor de goede werking van de website en kan op geen enkele wijze aansprakelijk gehouden worden voor een slechte werking of tijdelijke (on)beschikbaarheid van de website of voor enige vorm van schade, rechtstreekse of onrechtstreekse, die zou voortvloeien uit de toegang tot of het gebruik van de website. <br/>
      <br/>
      RADX BVBA kan in geen geval tegenover wie dan ook, op directe of indirecte, bijzondere of andere wijze aansprakelijk worden gesteld voor schade te wijten aan het gebruik van deze site of van een andere, inzonderheid als gevolg van links of hyperlinks, met inbegrip, zonder beperking, van alle verliezen, werkonderbrekingen, beschadiging van programma’s of andere gegevens op het computersysteem, van apparatuur, programmatuur of andere van de gebruiker. <br/>
      <br/>
      De website kan hyperlinks bevatten naar websites of pagina’s van derden, of daar onrechtstreeks naar verwijzen. Het plaatsen van links naar deze websites of pagina’s impliceert op geen enkele wijze een impliciete goedkeuring van de inhoud ervan. <br/>
      <br/>
      RADX BVBA verklaart uitdrukkelijk dat zij geen zeggenschap heeft over de inhoud of over andere kenmerken van deze websites en kan in geen geval aansprakelijk gehouden worden voor de inhoud of de kenmerken ervan of voor enige andere vorm van schade door het gebruik ervan. <br/>
      <br/>
      <h2 className="title is-2"> Toepasselijk recht en bevoegde rechtbanken.</h2>
      Het Belgisch recht is van toepassing op deze site. In geval van een geschil zijn enkel de rechtbanken van het arrondissement Antwerpen bevoegd. <br/>
      <br/>
      <h2 className="title is-2">Privacybeleid</h2>
      RADX BVBA hecht belang aan uw privacy. Hoewel de meeste informatie op deze site beschikbaar is zonder dat er persoonlijke gegevens moeten worden verstrekt, is het mogelijk dat de gebruiker om persoonlijke informatie gevraagd wordt. <br/>
      <br/>
      Deze informatie zal enkel gebruikt worden in het kader van ons klantenbeheer, om u op de hoogte te houden van onze activiteiten, om uw verblijf te boeken en u te contacteren bij onvoorziene omstandigheden en overmacht. De gebruiker kan zich, kosteloos en op verzoek, steeds verzetten tegen het gebruik van zijn gegevens voor direct marketing. Daartoe zal hij zich richten tot RADX BVBA, info@radx.eu. Uw persoonsgegevens worden nooit doorgegeven aan derden. <br/>
      <br/>
      Conform de wet verwerking persoonsgegevens beschikt de gebruiker over een wettelijk recht op inzage en eventuele correctie van zijn persoonsgegevens. Mits bewijs van identiteit (kopie identiteitskaart) kunt u  via een schriftelijke, gedateerde en ondertekende aanvraag aan RADX BVBA, gratis de schriftelijke mededeling bekomen van uw persoonsgegevens. Indien nodig kunt u ook vragen de gegevens te corrigeren die onjuist, niet volledig of niet pertinent zouden zijn. <br/>
      <br/>
      RADX BVBA kan anonieme of geaggregeerde gegevens verzamelen van niet-persoonlijke aard, zoals browser type, het besturingsprogramma dat u gebruikt of de domeinnaam van de website langs waar u naar de website gekomen bent, of waarlangs u die verlaat. Dit maakt het ons mogelijk de website permanent te optimaliseren voor de gebruikers. <br/>
      <br/>
      <h2 className="title is-2">Het gebruik van “cookies”</h2>
      <br/>
      Voor meer info over cookies verwijzen we naar onze <Link to="/cookies">cookies</Link> pagina. <br/>
      <br/>
      <h2 className="title is-2">Contacteer ons</h2>
      Bij vragen over deze privacy policy en over reserveringen van het Appartement? Gelieve gebruik te maken van het <Link to="/contact">contactformulier</Link>. <br/>
      <br/>
      <br/>
    </div>
  );
}

export default Privacy;
